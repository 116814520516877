body {
  color: #5d6d82;
  font-family: "Geomanist webfont", sans-serif;
}
.PhoneInput {
  height: 56px;
  background: #f8faff;
  padding-left: 10px;
  border-radius: 4px;
  .PhoneInputInput {
    background: transparent;
    outline: none;
    padding-left: 22px;
  }
}

#request-demo_overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1200;
}

.request-demo_modal {
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
  z-index: 1000;
  max-height: 90vh;
  display: block;
  overflow: auto;
  min-width: 375px;
  max-width: 480px;
  padding: 40px;
  border-radius: 10px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  font-family: "Geomanist", sans-serif;
  color: #5d6d82;

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    color: #555;
    padding: 0;
  }

  .select-label {
    @media (max-width: 760px) {
      font-size: 15px;
    }
  }
}
